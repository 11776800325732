.dynamic-circle-rating {
    display: flex;
    align-items: center;
}

.dynamic-circle-container {
    display: flex;
}

.dynamic-circle-container:not(:last-child) {
    margin-right: 5px;
}